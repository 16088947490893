import styled from 'styled-components'
import { EyeOffIconDefault } from '../atoms/icons/EyeOffIconDefault'
import { EyeOnIconDefault } from '../atoms/icons/EyeOnIconDefault'
import { FC } from 'react'

type EyeIconFormProps = {
  showPassword: boolean
  setShowPassword: (boolean) => void
}

const EyeIcon: FC<EyeIconFormProps> = ({ showPassword, setShowPassword }) => {
  return (
    <>
      {!showPassword ? (
        <StyledEyeOnIcon onClick={() => setShowPassword(true)} />
      ) : (
        <StyledEyeOffIcon onClick={() => setShowPassword(false)} />
      )}
    </>
  )
}

export default EyeIcon

const StyledEyeOffIcon = styled(EyeOffIconDefault)`
  &:hover {
    cursor: pointer;
  }
`
const StyledEyeOnIcon = styled(EyeOnIconDefault)`
  &:hover {
    cursor: pointer;
  }
`
