import { MediumTinyBold, Tiny2 } from '@/components/atoms/typography'
import { FC, Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  Select,
  SelectItem,
  SelectLayoutType
} from '@/components/molecules/select'
import EyeIcon from '../eyeIcon'
import { Flex } from '@/components/atoms/Grid'

export enum FormInputType {
  TextArea,
  Text,
  File,
  Select,
  Section
}

type FormInputProps = {
  name: string
  formInputType?: FormInputType
  placeholder?: string
  type?: string
  isError?: boolean
  value: string
  items?: SelectItem[]
  onChange: (name: string, value: string) => void
  onFileChange?: (name: string, value: FileList) => void
  errorPlaceholder?: string
} & Rest

const FormInput: FC<FormInputProps> = ({
  name,
  formInputType = FormInputType.Text,
  type,
  isError,
  placeholder,
  value,
  onChange,
  onFileChange,
  items = [],
  errorPlaceholder,
  ...rest
}) => {
  const handleChange = (e) => onChange(e.target.name, e.target.value)
  const handleFileChange = (e) => {
    handleChange(e)
    onFileChange && onFileChange(e.target.name, e.target.files)
  }

  switch (formInputType) {
    case FormInputType.TextArea:
      return (
        <TextArea
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          value={value ?? ''}
          isError={isError}
          {...rest}
        />
      )
    case FormInputType.File:
      return (
        <FileInput
          name={name}
          onChange={handleFileChange}
          placeholder={placeholder}
          value={value ?? ''}
          isError={isError}
          {...rest}
        />
      )
    case FormInputType.Select:
      return (
        <StyledSelect
          name={name}
          placeholder={placeholder}
          isError={isError}
          height={30}
          items={items}
          layoutType={SelectLayoutType.arrowAtEnd}
          selectedIndex={items.findIndex((item) => item.id === value)}
          onSelect={(value) => onChange(name, value.toString())}
        />
      )
    case FormInputType.Section:
      return null
    default:
      return (
        <TextInput
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          type={type}
          isError={isError}
          value={value ?? ''}
          errorPlaceholder={errorPlaceholder}
          {...rest}
        />
      )
  }
}

export default FormInput

const TextArea: FC<FormInputProps> = ({
  isError,
  placeholder,
  adjustPlaceholderPosition,
  value,
  name,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [hasValue, setHasValue] = useState<boolean>(!!value)

  useEffect(() => {
    setHasValue(!!value)
  }, [value])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Flex width="100%" position="relative">
      <StyledTextArea
        isError={isError}
        name={name}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      <LabelWrapper adjustPlaceholderPosition={adjustPlaceholderPosition}>
        <StyledTextLabel movePlaceholder={isFocused || hasValue}>
          {placeholder}
        </StyledTextLabel>
      </LabelWrapper>
    </Flex>
  )
}

const TextInput: FC<FormInputProps> = ({
  isError,
  type,
  name,
  placeholder,
  errorPlaceholder,
  adjustPlaceholderPosition,
  value,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [hasValue, setHasValue] = useState<boolean>(!!value)

  useEffect(() => {
    setHasValue(!!value)
  }, [value])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <Flex width="100%" position="relative">
      <StyledInput
        value={value}
        name={name}
        type={showPassword ? 'text' : type}
        isError={isError}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      <Fragment>
        <LabelWrapper adjustPlaceholderPosition={adjustPlaceholderPosition}>
          <StyledTextLabel movePlaceholder={isFocused || hasValue}>
            {placeholder}
          </StyledTextLabel>
        </LabelWrapper>
        <ErrorWrapper adjustPlaceholderPosition={adjustPlaceholderPosition}>
          {isError && (
            <StyledMediumTinyBold>{errorPlaceholder}</StyledMediumTinyBold>
          )}
        </ErrorWrapper>
      </Fragment>
      {type === 'password' && (
        <IconWrapper data-cy="loginEyeIcon">
          <EyeIcon
            showPassword={showPassword}
            setShowPassword={setShowPassword}
          />
        </IconWrapper>
      )}
    </Flex>
  )
}

const FileInput: FC<FormInputProps> = ({ isError, ...rest }) => {
  const { placeholder } = rest
  return (
    <StyledLabel isError={isError}>
      {placeholder && <Tiny2>{placeholder}</Tiny2>}
      <StyledFileInput type="file" {...rest} />
    </StyledLabel>
  )
}

const InputBase = css`
  border: 1px solid
    ${(props) =>
      props.isError
        ? props.theme.colors.errorSecondary
        : props.theme.colors.onBackground};
  border-radius: ${(props) => props.theme.borderRadius}px;
  flex-grow: 1;
  margin: 0;
  &::placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholder};
  }
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.inputPlaceholder};
  }
  &:focus {
    outline-style: none;
    border: 1px solid
      ${(props) =>
        props.isError
          ? props.theme.colors.errorSecondary
          : props.theme.colors.onBackground};
  }
`

const StyledTextLabel = styled.label`
  font-weight: 500;
  transition: all 0.125s ease-out;
  color: ${({ theme }) => theme.colors.inputPlaceholder};
  margin-top: ${(props) => (props.movePlaceholder ? '4px' : '20px')};
  font-size: ${(props) => (props.movePlaceholder ? '12px' : '16px')};
`

const StyledInput = styled.input`
  ${InputBase};
  width: ${(props) => props.width || 'auto'};
  height: 60px;
  padding: ${(props) => props.p ?? props.padding ?? '0 13px'};
  font-size: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  flex-grow: 1;
  &:-webkit-autofill,
  &:autofill {
    + div label {
      font-weight: 500;
      transition: all 0.125s ease-out;
      color: ${({ theme }) => theme.colors.inputPlaceholder};
      margin-top: 4px;
      font-size: 12px;
    }
  }
`

const StyledTextArea = styled.textarea`
  ${InputBase};
  height: ${(props) => props.height || '114px'};
  padding: 20px 13px 13px 13px;
  resize: none;
  flex-grow: 1;
  font-size: 12px;
  &:-webkit-autofill,
  &:autofill {
    + div label {
      font-weight: 500;
      transition: all 0.125s ease-out;
      color: ${({ theme }) => theme.colors.inputPlaceholder};
      margin-top: 4px;
      font-size: 12px;
    }
  }
`

const StyledLabel = styled.label`
  ${InputBase};
  text-align: center;
  border-style: dashed;
  height: ${(props) => props.height || '38px'};
  padding: 0;
  flex-grow: 1;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.onBackground};
    border-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.background};
  }
`

const StyledFileInput = styled.input`
  display: none;
`
const StyledSelect = styled(Select)`
  border-radius: 0;
`
const IconWrapper = styled(Flex)`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`

const LabelWrapper = styled(Flex)`
  position: absolute;
  pointer-events: none;
  left: ${(props) => !props.adjustPlaceholderPosition && '13px'};
  padding-left: ${(props) => props.adjustPlaceholderPosition && '13px'};
`
const StyledMediumTinyBold = styled(MediumTinyBold)`
  min-height: 13px;
  width: 100%;
  color: ${({ theme }) => theme.colors.errorSecondary};
  margin: 0;
`
const ErrorWrapper = styled(Flex)`
  position: absolute;
  left: ${(props) => !props.adjustPlaceholderPosition && '13px'};
  padding-left: ${(props) => props.adjustPlaceholderPosition && '13px'};
  margin-top: 46px;
`
