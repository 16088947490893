import { FC } from 'react'
import styled, { css } from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import { Tiny2 } from '@/components/atoms/typography'
import { SingleIconProps } from '@/components/atoms/icon'
import Image from 'next/image'
import { ArrowDownIconDefault } from '../atoms/icons/ArrowDownIconDefault'

export type SelectItem = {
  id: string | number
  title: string
  disabled?: boolean
  iconPath?: string
}

export enum SelectLayoutType {
  arrowAtEnd,
  arrowNextToText,
  table
}

type SelectProps = {
  placeholder?: string
  selectedIndex?: number
  onSelect: (id: string | number) => void
  items: SelectItem[]
  TextElement?: JSX.Element
  SelectIcon?: (props: SingleIconProps) => JSX.Element
  layoutType?: SelectLayoutType
  showTitle?: string
  showArrowDown?: boolean
  isError?: boolean
  emptyValue?: string
  hideText?: string
} & Rest

export const Select: FC<SelectProps> = ({
  items,
  selectedIndex,
  placeholder,
  onSelect,
  showTitle,
  SelectIcon,
  showArrowDown = true,
  TextElement = Tiny2,
  layoutType = SelectLayoutType.arrowNextToText,
  isError = false,
  emptyValue,
  hideText,
  ...rest
}) => {
  const item = items[selectedIndex]
  const handleOnChange = (e) => {
    const index = items.findIndex(({ id }) => id === e.target.value)
    if (index > -1) {
      onSelect(items[index].id)
    }
  }
  const title = showTitle ?? item?.title ?? placeholder ?? emptyValue
  const defaultValue = item?.id ?? emptyValue ?? ''
  const width = layoutType === SelectLayoutType.arrowAtEnd ? '100%' : 'auto'
  return (
    <SelectWrapper
      layoutType={layoutType}
      isError={isError}
      data-cy="selectWrapper"
      {...rest}
    >
      <Flex width={width} justifyContent="space-between">
        <Flex alignItems="center">
          {SelectIcon && <SelectIcon mr="5px" />}
          {items[selectedIndex]?.iconPath && (
            <Box mr={10}>
              <Image
                src={items[selectedIndex].iconPath}
                width={25}
                height={11}
                objectFit="contain"
                unoptimized
              />
            </Box>
          )}
          {!hideText && <TextElement m={1}>{title}</TextElement>}
        </Flex>
        {showArrowDown && <ArrowDownIconDefault />}
      </Flex>
      {items && items.length > 0 && (
        <StyledSelect
          size={1}
          onChange={handleOnChange}
          defaultValue={defaultValue}
          key={title}
        >
          {!item && emptyValue && <option disabled={true}>{emptyValue}</option>}
          {items.map(({ title, disabled, id }, offset) => (
            <option disabled={disabled} key={offset} value={id}>
              {title}
            </option>
          ))}
        </StyledSelect>
      )}
    </SelectWrapper>
  )
}

const TableSelectWrapperStyles = css`
  justify-content: flex-start;
  padding: 10px 23px;
`

const NormalSelectWrapperStyles = css`
  border: 1px solid
    ${(props) =>
      props.isError
        ? props.theme.colors.errorSecondary
        : props.theme.colors.onBackground};
  padding: 2px 13px;
  border-radius: 2px;
  justify-content: space-between;
`

type SelectWrapperProps = {
  layoutType: SelectLayoutType
}

const SelectWrapper = styled<SelectWrapperProps>(Flex)`
  flex-direction: row;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.layoutType === SelectLayoutType.table
      ? TableSelectWrapperStyles
      : NormalSelectWrapperStyles};
  border-radius: ${(props) => props.theme.borderRadius}px;
`

const StyledSelect = styled.select`
  opacity: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 14px;
  height: auto !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
`
